import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { WarehouseLinesService } from './../../services/whl.service';
import { TrancheDetail } from './../../config/table-structure-tranche';
import {
  SharedService, NoRowsOverlayFlags, DriveFrom, SnackbarConfig, ScreenActionPrivileges,
  GridMaster, BrowserStorageService, StorageKey, AuthUtils
} from '@wlms-web/utils';
import * as moment from 'moment';
import { UiHelperService, AlertModalComponent } from '@wlms-web/ui-kit';
import { ManageTrancheComponent } from './manage-tranche/manage-tranche.component';
import { CopyTrancheComponent } from './copy-tranche/copy-tranche.component';
import * as _ from 'lodash';

@Component({
  selector: 'wlms-tranche',
  templateUrl: './tranche.component.html',
  styleUrls: ['./tranche.component.scss']
})
export class TrancheComponent implements OnInit {

  rowData = [];
  columnDefs = [...TrancheDetail];
  noRowsOverlayFlags = { ...NoRowsOverlayFlags }.TRANCHES;
  paginationPageSize = 50;
  userDetails: any;
  @Input() suppressActionPrivilege = false;
  @Input() suppressRightClickPrivilege = false;
  @Input() warehouse: any;
  contracts = [];
  groupDefaultExpanded = -1;
  currentActiveContract: any;
  @ViewChild('tracheGrid') tracheGrid: any;
  screenActionPrivileges = ScreenActionPrivileges;
  autoGroupColumnDef = {
    headerName: '',
    cellRenderer: 'agGroupCellRenderer',
    suppressMovable: true,
    suppressColumnsToolPanel: true,
    suppressMenu: true,
    width: 135,
    active: true,
    sortable: false,
    cellRendererParams: {
      suppressCount: true,
      innerRenderer: 'iconFormatRendererComponent',
    }
  };

  preferance: any[] = [];
  filterPreferance: any[] = [];
  filterModel: any;
  disableResetPreference = false;
  columnMaster = [];
  getDataPath = (data) => data.TranchHierarchy;
  selectedTabPreference: any[] = [];

  constructor(private warehouseLinesService: WarehouseLinesService,
    private uiHelperService: UiHelperService,
    public browserStorageService: BrowserStorageService,
    public sharedService: SharedService,
    public authUtils: AuthUtils) { }

  ngOnInit(): void {
    this.getColumnMaster();
    this.getContracts();
    this.getUserPreference();
    this.userDetails = this.authUtils.getLoggedInUserDetails();
  }

  // Get contracts
  getContracts() {
    this.warehouseLinesService.getContractsPerWarehouse(this.warehouse.WarehouseLineId).subscribe(response => {
      if (response) {
        this.contracts = response['details'];
        this.setActiveContract();
      }
    });
  }

  onGridReady() {
    this.tracheGrid.setRowData(this.rowData);
    this.sharedService.loadGrid.next({ isLoad: false });
  }

  setActiveContract() {
    this.contracts.forEach((x, index) => {
      this.contracts[index] = {
        ...x, name: x.WarehouseLineName + '  ' + moment(new Date(x.StartDate)).format('MM/DD/YYYY') + ' - ' +
          moment(new Date(x.ExpiryDate)).format('MM/DD/YYYY')
      }
    });
    const index = this.contracts.findIndex(x => {
      if (moment(new Date()).isSame(x.StartDate, 'day') ||
        moment(new Date()).isSame(x.ExpiryDate, 'day') ||
        moment(new Date()).startOf('day').isBetween(x.StartDate, x.ExpiryDate, undefined, '[]')) {
        return x;
      }
    });
    if (index != -1) {
      this.currentActiveContract = { ...this.contracts[index] };
      this.getTranches(this.currentActiveContract.WarehouseLineContractDetailId);
    } else {
      this.currentActiveContract = null;
      this.rowData = [];
    }
  }

  // Api to get tranche details
  getTranches(contractId) {
    this.rowData = [];
    this.sharedService.loadGrid.next({ isLoad: true });
    this.warehouseLinesService.getTranches(contractId).subscribe(response => {
      if (response) {
        this.rowData = response['details'];
        this.modifyRowData();
        this.sharedService.loadGrid.next({ isLoad: false });
      }
    })
  }

  // Modify row data
  modifyRowData() {
    this.rowData.forEach((x, index) => {
      this.rowData[index] = {
        ...x,
        TranchHierarchy: x.TranchePath.split('/').filter(y => y),
        WetStartingOutstandingAmount: x.WetStartingOutstandingAmount ? x.WetStartingOutstandingAmount : 0,
        AmendedWetStartingOutstandingAmount: x.AmendedWetStartingOutstandingAmount ? x.AmendedWetStartingOutstandingAmount : 0,
        StartingOutstandingAmount: parseFloat(x.WetStartingOutstandingAmount ? x.WetStartingOutstandingAmount : 0) +
          parseFloat(x.DryStartingOutstandingAmount ? x.DryStartingOutstandingAmount : 0),
        AmendedStartingOutstandingAmount: x.AmendedWetStartingOutstandingAmount > 0 ?
          (parseFloat(x.AmendedDryStartingOutstandingAmount ? x.AmendedDryStartingOutstandingAmount :
            x.DryStartingOutstandingAmount ? x.DryStartingOutstandingAmount : 0) +
            parseFloat(x.AmendedWetStartingOutstandingAmount)) : 0,
        DriverAmount: x.DriveFrom == DriveFrom.Amount ? x.DriverValue : 0,
        DriverPercentage: x.DriveFrom == DriveFrom.Limit ? x.DriverValue : 0
      };
    });
  }

  // On contract changed
  contractChanged(contract) {
    this.currentActiveContract = { ...contract };
    this.getTranches(contract.WarehouseLineContractDetailId);
  }

  noOverlay(event) {
    this.tracheGrid.grid.api.setGridOption("noRowsOverlayComponentParams", {
      noRowsMessageFunc: () => {
        return {
          blankTemplateFlag: 'tranches',
        };
      }
    });
  }

  openEditTranche(event) {
    this.setParentListForEdit(event)
    const data = {
      isEdit: true,
      modalData: {
        ...event.data,
        tranches: [{ WarehouseLineTrancheId: 0, Name: 'None' }].
          concat(this.setParentListForEdit(event).
            map(({ WarehouseLineTrancheId, Name }) => ({ WarehouseLineTrancheId, Name })).
            filter(x => x.WarehouseLineTrancheId != event.data.WarehouseLineTrancheId)),
        WarehouseLineName: this.warehouse.WarehouseLineName,
        LineLimitAmount: this.warehouse.LineLimitAmount,
        Contract: moment(new Date(this.currentActiveContract.StartDate)).format('MM/DD/YYYY') + ' - ' +
          moment(new Date(this.currentActiveContract.ExpiryDate)).format('MM/DD/YYYY'),
        WarehouselineContractId: this.currentActiveContract.WarehouseLineContractDetailId,
        ParentTrancheId: event.data.TranchHierarchy.length <= 1 ? 0 : this.getParentTrache(event),
        FloorRate: this.warehouse.FloorRate,
        StartingOutStandingAmount: event.data.WetStartingOutstandingAmount + event.data.WetStartingOutstandingAmount,
        AmendedStartOutAmt: (event.data.AmendedWetStartingOutstandingAmount > 0 || event.data.AmendedDryStartingOutstandingAmount > 0) ?
          parseFloat(event.data.AmendedWetStartingOutstandingAmount) + parseFloat(event.data.AmendedDryStartingOutstandingAmount) :
          null,
        DriveFrom: event.data.DriveFrom ? event.data.DriveFrom : 0,
        DriverValue: event.data.DriverValue ? event.data.DriverValue : 0,
        PricingMargin: event.data.PricingMargin ? event.data.PricingMargin : 0,
        AdvanceRate: event.data.AdvanceRate ? event.data.AdvanceRate : 0,
        PricingRate: ((Math.max(event.data.IndexRate ? event.data.IndexRate : this.warehouse.IndexRate ? this.warehouse.IndexRate : 0,
          this.warehouse.FloorRate) + (event.data.PricingMargin ? event.data.PricingMargin : 0))).toFixed(2)
      },
    };
    const title = 'Edit Tranche';
    this.uiHelperService.askConfirmation(ManageTrancheComponent, 'modal-lg', title, data, 'Cancel', 'Update',
      (data: any) => {
        this.saveTranche(this.setTranchData(data.data), 'edit');
      });
  }

  setParentListForEdit(event) {
    return this.rowData.
      filter((x) =>
        x.TranchHierarchy.length - 1 <= event.data.TranchHierarchy.length - 1 ||
        (x.TranchHierarchy.length - 1 > event.data.TranchHierarchy.length - 1 &&
          x.TranchHierarchy[event.data.TranchHierarchy.length - 1] != event.data.TranchHierarchy[event.data.TranchHierarchy.length - 1]))
  }

  openAddTranche(event) {
    const data = {
      isEdit: false,
      modalData: this.setModalDataForAdd(event),
    };
    const title = 'Add Tranche';
    this.uiHelperService.askConfirmation(ManageTrancheComponent, 'modal-lg', title, data, 'Cancel', 'Save',
      (data: any) => {
        this.saveTranche(this.setTranchData(data.data), 'add');
      });
  }

  addParentTranche() {
    const data = {
      isEdit: false,
      modalData: this.setModalDataForAdd(),
    };
    const title = 'Add Tranche';
    this.uiHelperService.askConfirmation(ManageTrancheComponent, 'modal-lg', title, data, 'Cancel', 'Save',
      (data: any) => {
        this.saveTranche(this.setTranchData(data.data), 'add');
      });
  }

  setModalDataForAdd(event?) {
    return {
      WarehouseLineTrancheId: 0,
      Name: '',
      ParentTrancheId: event ? event.data.WarehouseLineTrancheId : 0,
      WarehouselineContractId: this.currentActiveContract.WarehouseLineContractDetailId,
      DriveFrom: 0,
      DriverValue: 0,
      PricingMargin: 0,
      AdvanceRate: 0,
      AmendedWetStartingOutstandingAmount: 0,
      AccountNumber: '',
      Comments: '',
      IsDeleted: false,
      tranches: [{ WarehouseLineTrancheId: 0, Name: 'None' }].
        concat(this.rowData.map(({ WarehouseLineTrancheId, Name }) => ({ WarehouseLineTrancheId, Name }))),
      WarehouseLineName: this.warehouse.WarehouseLineName,
      LineLimitAmount: this.warehouse.LineLimitAmount,
      Contract: moment(new Date(this.currentActiveContract.StartDate)).format('MM/DD/YYYY') + ' - ' +
        moment(new Date(this.currentActiveContract.ExpiryDate)).format('MM/DD/YYYY'),
      FloorRate: this.warehouse.FloorRate,
      AmendedStartOutAmt: null,
      IndexRate: event ? event.data.IndexRate : this.warehouse.IndexRate ? this.warehouse.IndexRate : 0,
      PricingRate: Math.max(event ? event.data.IndexRate : this.warehouse.IndexRate ? this.warehouse.IndexRate : 0,
        this.warehouse.FloorRate) + 0,
      Limit: 0,
      StartingOutStandingAmount: this.warehouse.StartingOutStandingAmount
    }
  }

  setTranchData(data) {
    return {
      WarehouseLineTrancheId: data.modalData.WarehouseLineTrancheId,
      Name: data.modalData.Name,
      ParentTrancheId: data.modalData.ParentTrancheId,
      WarehouselineContractId: data.modalData.WarehouselineContractId,
      DriveFrom: data.modalData.DriveFrom ? data.modalData.DriveFrom : 0,
      DriverValue: data.modalData.DriverValue ? data.modalData.DriverValue : 0,
      PricingMargin: data.modalData.PricingMargin ? data.modalData.PricingMargin : 0,
      AdvanceRate: data.modalData.AdvanceRate ? data.modalData.AdvanceRate : 0,
      AmendedWetStartingOutstandingAmount: data.modalData.AmendedWetStartingOutstandingAmount ?
        data.modalData.AmendedWetStartingOutstandingAmount : 0,
      AmendedDryStartingOutstandingAmount: data.modalData.AmendedWetStartingOutstandingAmount ? data.modalData.AmendedStartOutAmt -
        data.modalData.AmendedWetStartingOutstandingAmount : 0,
      AmendedStartingOutstandingAmount: data.modalData.AmendedStartOutAmt > 0 ?
        data.modalData.AmendedStartOutAmt : data.modalData.AmendedStartingOutstandingAmount,
      IsAvailableForFunding: data.modalData.IsAvailableForFunding,
      Comments: data.modalData.Comments ? data.modalData.Comments : '',
      IsDeleted: false,
    }
  }

  getParentTrache(event) {
    return this.rowData.filter(x => x.TranchHierarchy.length == event.data.TranchHierarchy.length - 1
      && x.TranchHierarchy[x.TranchHierarchy.length - 1] == event.data.TranchHierarchy[x.TranchHierarchy.length - 1] &&
      event.data.TranchHierarchy.filter((element, index) => index < event.data.TranchHierarchy.length - 1).
        every((e) => new Set(x.TranchHierarchy).has(e))).map(y => y.WarehouseLineTrancheId)[0];
  }

  saveTranche(data, type) {
    this.warehouseLinesService.saveTranche(data).subscribe(response => {
      if (response && response['details'] && response['details'].Status == 1) {
        type == 'delete' ?
          this.uiHelperService.showSnackBar('Tranche deleted successfully', SnackbarConfig.success) :
          this.uiHelperService.showSnackBar('Tranche saved successfully', SnackbarConfig.success);
        this.getTranches(this.currentActiveContract.WarehouseLineContractDetailId);
      } else if (response && response['details'] && response['details'].Status == 3 && type == 'delete') {
        this.uiHelperService.showSnackBar(`Cannot delete tranche as it or any of it's children has loans assigned under them.`,
          SnackbarConfig.error);
      }
    })
  }

  deleteTranche(event) {
    const data = {
      IsDeleted: true,
      WarehouselineContractId: this.currentActiveContract.WarehouseLineContractDetailId,
      ParentTrancheId: event.data.TranchHierarchy.length > 1 ? this.getParentTrache(event) : 0,
      WarehouseLineTrancheId: event.data.WarehouseLineTrancheId,
      Name: event.data.Name,
      DriveFrom: event.data.DriveFrom ? event.data.DriveFrom : 0,
      DriverValue: event.data.DriverValue ? event.data.DriverValue : 0,
      PricingMargin: event.data.PricingMargin ? event.data.PricingMargin : 0,
      AdvanceRate: event.data.AdvanceRate ? event.data.AdvanceRate : 0,
      AccountNumber: event.data.AccountNumber,
      AmendedStartingOutstandingAmount: event.data.AmendedStartOutAmt > 0 ?
        event.data.AmendedStartOutAmt : event.data.AmendedStartingOutstandingAmount,
      IsAvailableForFunding: event.data.IsAvailableForFunding,
      Comments: event.data.Comments ? event.data.Comments : '',
      AmendedWetStartingOutstandingAmount: event.data.AmendedWetStartingOutstandingAmount ?
        event.data.AmendedWetStartingOutstandingAmount : 0,
      AmendedDryStartingOutstandingAmount: event.data.AmendedWetStartingOutstandingAmount ?
        event.data.AmendedStartOutAmt - event.data.AmendedWetStartingOutstandingAmount : 0,
    }
    this.saveTranche(data, 'delete');
  }

  openWlCopyTranche(event) {
    const activeContracts = this.contracts.filter(x => moment(moment(new Date()).startOf('day'))
      .diff(moment(x.ExpiryDate).startOf('day')) <= 0)
      .slice()
      .sort((a, b) => {
        a.ExpiryDate = new Date(a.ExpiryDate)
        b.ExpiryDate = new Date(b.ExpiryDate)
        return a.ExpiryDate - b.ExpiryDate
      });
    const data = {
      isEdit: false,
      modalData: event,
      ParentTrancheId: event.data.TranchHierarchy.length > 1 ?
        this.getParentTrache(event) : 0,
      contracts: activeContracts,
      activeContract: activeContracts[0]
    };
    const title = 'Add Tranche';
    if (activeContracts.length > 0) {
      this.uiHelperService.askConfirmation(CopyTrancheComponent, 'modal-md', title, data, 'Cancel', 'Save',
        (data: any) => {
          let tranche = {
            "Name": data.data.modalData.newTranche,
            "SourceTrancheId": data.data.modalData.data.WarehouseLineTrancheId,
            "ParentTrancheId": data.data.modalData.tranchId ? data.data.modalData.tranchId : 0,
            "TargetWarehouselineContractId": data.data.modalData.contractId
          }
          this.currentActiveContract.WarehouseLineContractDetailId = data.data.currentAvtiveContractId;
          this.copyTranche(tranche);
        });
    } else {
      this.uiHelperService.showSnackBar('There are contracts under which the tranche can be copied into.', SnackbarConfig.error);
    }
  }

  copyTranche(data) {
    this.warehouseLinesService.copyTanche(data).subscribe(response => {
      if (response && response['details'] && response['details'].Status == 1) {
        this.getTranches(this.currentActiveContract.WarehouseLineContractDetailId);
        this.uiHelperService.showSnackBar('Copied Tranche Successfully', SnackbarConfig.success);
      } else if (response && response['details'] && response['details'].Status == 2) {
        this.uiHelperService.showSnackBar(`Failed to save as the the same tanche exists.`, SnackbarConfig.error);
      }
    })

  }

  showUserPreferenceModal() {
    const title = ' Save User Preferences';
    const data = {
      data: 'You are about to save User Preference.'
    };
    this.uiHelperService.askConfirmation(AlertModalComponent, 'modal-md', title, data, 'Cancel', 'Save Preference', () => {
      this.saveUserPreferences();
    });
  }

  saveUserPreferences() {
    this.reorderColumnMaster();
    this.collectUserPreferences();
    this.selectedTabPreference = [];
    this.selectedTabPreference.push({"tabid":GridMaster.Tranche});

    const request = {
      Tab: this.selectedTabPreference,
      Column: this.preferance
    };
    this.warehouseLinesService.saveUserPreferences(request).subscribe((response: any) => {
      if (response) {
        this.sharedService.userPreferenceChanged = false;
        this.uiHelperService.showSnackBar('User preferences saved successfully', SnackbarConfig.success);
        this.getUserPreference();
        this.disableResetPreference = true;
      }
    }, (error) => {
      this.uiHelperService.showSnackBar('Failed to save user preference', SnackbarConfig.error);
    });
  }

  getColumnMaster() {
    this.warehouseLinesService.getColumnMaster(GridMaster.Tranche).subscribe((response: any) => {
      if (response) {
        this.columnMaster = response.details;
        this.reorderColumnMaster();
      }
    });
  }

  reorderColumnMaster() {
    _.forEach(this.columnDefs, (col: any, index: number) => {
      const masterIndex = _.findIndex(this.columnMaster, (master: any) => {
        return master.ColumnName == col.field;
      });
      if (masterIndex != -1) {
        this.columnDefs[index]['ColumnMasterId'] = this.columnMaster[masterIndex].ColumnMasterId;
      }
    });
  }

  collectUserPreferences() {
    this.preferance = [];
    const columnState = this.tracheGrid.getColumnState();
    _.forEach(columnState, (state: any, index: number) => {
      let columnMasterId = null;
      const columnIndex = _.findIndex(this.columnDefs, (col) => {
        return col.field == state.colId;
      });
      if (columnIndex != -1) {
        const col = { ...this.columnDefs[columnIndex] };
        columnMasterId = col['ColumnMasterId'];
        if (columnMasterId > 0) {
          this.preferance.push({
            ColumnMasterId: columnMasterId,
            ColumnOrder: index,
            SortOrder: state.sort,
            GroupOrder: state.rowGroupIndex != null ? state.rowGroupIndex + 1 : state.rowGroupIndex,
            IsVisible: state.hide ? 0 : 1
          });
        }
      }
    });
  }

  getUserPreference() {
    if (this.authUtils.doesUserAllowedToPerform(this.screenActionPrivileges.GetTrancheTabSavePreference)) {
      this.warehouseLinesService.getUserPreferences(this.userDetails?.email, 'Tranches').subscribe(response => {
        this.persistFilterData();
        if (response) {
          this.preferance = response.details;
          this.disableResetPreference = this.preferance.map((pref) => pref.TabId).includes(GridMaster.Tranche);
          this.setPreference()
        }
      });
    }
  }

  setPreference() {
    this.reorderColumnMaster();
    const tabPreference = _.filter(this.preferance, (pref: any) => {
      return pref.TabId == GridMaster.Tranche;
    });
    if (tabPreference && tabPreference.length > 0) {
      _.forEach(this.columnDefs, (col: any, index: number) => {
        const prefColumn = _.filter(tabPreference, (pref: any) => {
          return pref.ColumnMasterId == col.ColumnMasterId;
        });
        const column = { ...this.columnDefs[index] };
        this.columnDefs[index] = this.updateColumnProps(prefColumn, column);
      });
      const sortedCols = _.sortBy(this.columnDefs, (col: any) => {
        return col['ColumnOrder'];
      });
      this.columnDefs = [...sortedCols];
    } else {
      this.columnDefs = [...TrancheDetail];
    }
  }

  updateColumnProps(prefColumn, column) {
    if (prefColumn && prefColumn.length > 0) {
      column['sort'] = prefColumn[0].SortOrder;
      column['ColumnOrder'] = prefColumn[0].ColumnOrder;
      column['hide'] = prefColumn[0].IsVisible == 1 ? false : true;
      if (prefColumn[0].GroupOrder > 0) {
        column['rowGroup'] = true;
        column['rowGroupIndex'] = prefColumn[0].GroupOrder - 1;
      } else {
        column['rowGroup'] = false;
      }
    }
    return column;
  }

  persistFilterData() {
    this.filterPreferance = [];
    this.filterModel = [];
    this.filterPreferance = this.browserStorageService.getLocalStorageValue(StorageKey.FILTERMODEL);
    const currentFilterData = this.filterPreferance ? this.filterPreferance.filter(x => x.tabId === GridMaster.Tranche)[0] : null;
    if (currentFilterData) {
      this.filterModel = currentFilterData.filterData;
      this.tracheGrid.setFilterModel(this.filterModel);
    }
    else {
      this.tracheGrid.setFilterModel(null);
    }
  }

  isUserPreferenceChanged() {
    return this.tracheGrid && this.tracheGrid.isUserPreferenceChanged();
  }

}

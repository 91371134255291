import { CurrencyFormatter, PercentageFormatter } from '@wlms-web/utils';

export const WarehouseLinesdetails = [
  {
    "field": "warehouseLineName",
    "headerName": "Warehouse Line",
    "filter": "agTextColumnFilter",
    "width": 150,
  },
  {
    "field": "contractExpiryDate",
    "headerName": "Line Limit Exp. Date",
    "cellRenderer": "formatDateRenderer",
    "filter": "agDateColumnFilter",
    "width": 200,
  },
  {
    "field": "lineLimitAmount",
    "headerName": "Line Limit Amt",
    "width": 180,
    "filter": "agNumberColumnFilter",
    valueFormatter: CurrencyFormatter.setCurrencyFormat,
    cellStyle: { 'text-align': 'right' }
  },
  {
    "field": "committedAmount",
    "headerName": "Committed Amt",
    "width": 180,
    "filter": "agNumberColumnFilter",
    valueFormatter: CurrencyFormatter.setCurrencyFormat,
    cellStyle: { 'text-align': 'right' },
  },
  {
    "field": "uncommittedAmount",
    "headerName": "Uncommitted Amt",
    "width": 200,
    "filter": "agNumberColumnFilter",
    valueFormatter: CurrencyFormatter.setCurrencyFormat,
    cellStyle: { 'text-align': 'right' }
  },
  {
    "field": "startingOutStandingAmount",
    "headerName": "Starting Outstanding Amt",
    "width": 180,
    "filter": "agNumberColumnFilter",
    valueFormatter: CurrencyFormatter.setCurrencyFormat,
    cellStyle: { 'text-align': 'right' }
  },
   {
    "field": "amendendStartingOutStandingAmount",
    "headerName": 'Amended Starting Outstanding Amt',
    "width": 180,
    "filter": "agNumberColumnFilter",
    valueFormatter: CurrencyFormatter.setCurrencyFormat,
    cellStyle: { 'text-align': 'right' }
  },
  {
    "field": "outStandingAmount",
    "headerName": "Outstanding Amt",
    "width": 180,
    "filter": "agNumberColumnFilter",
    valueFormatter: CurrencyFormatter.setCurrencyFormat,
    cellStyle: { 'text-align': 'right' }
  },
  {
    "field": "availableAmount",
    "headerName": "Available Amt",
    "width": 180,
    "filter": "agNumberColumnFilter",
    valueFormatter: CurrencyFormatter.setCurrencyFormat,
    cellStyle: { 'text-align': 'right' }
  },
  {
    "field": "amendedLineLimitAmount",
    "headerName": "Amended Line Limit Amt",
    "width": 220,
    "filter": "agNumberColumnFilter",
    valueFormatter: CurrencyFormatter.setCurrencyFormat,
    cellStyle: { 'text-align': 'right' }
  },
  {
    "field": "contractAmendmentExpiryDate",
    "headerName": "Amended Line Amt Exp. Date",
    "cellRenderer": "formatDateRenderer",
    "filter": "agDateColumnFilter",
    "width": 220,
  },
  {
    "field": "indexName",
    "headerName": "Index Name",
    "filter": "agNumberColumnFilter",
    "width": 150,
  },
  {
    "field": "floorRate",
    "headerName": "Floor Rate",
    valueFormatter: PercentageFormatter.setPercentageFormat,
    "filter": "agNumberColumnFilter",
    "width": 150,
  },
  {
    "field": "tableFunding",
    "headerName": "Table Funding",
    "filter": "agSetColumnFilter",
    "width": 180,
    valueFormatter: (params: any) => { return params.value ? 'Y' : 'N' },
  },
  {
    "field": "penaltyRate",
    "headerName": "Penalty Rate",
    valueFormatter: PercentageFormatter.setPercentageFormat,
    "filter": "agNumberColumnFilter",
    "width": 150,
  },
  {
    "field": "wetLimitAmount",
    "headerName": "Wet Limit Amt",
    "width": 220,
    "filter": "agNumberColumnFilter",
    valueFormatter: CurrencyFormatter.setCurrencyFormat,
    cellStyle: { 'text-align': 'right' }
  },

  {
    "field": "comments",
    "headerName": "Comments",
    "filter": "agTextColumnFilter",
    "width": 300,
    tooltipField: 'Comments'
  },
  {
    "field": "action",
    "headerName": "",
    "width": 100,
    "active": true,
    "cellRenderer": "actionColumnRendererComponent",
    "resizable": false,
    "pinned": "right",
    "suppressMovable": true,
    "suppressSorting": true,
    "suppressRowClickSelection": true,
    "lockPinned": true,
    showRowGroup: true,
    "suppressColumnsToolPanel": true,
    "sortable": false,
    "suppressMenu": true
  }
]

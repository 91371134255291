import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import {
  SharedService, NoRowsOverlayFlags, ScreenActionPrivileges, SnackbarConfig, WarehouseLineTabs, GridMaster,
  StorageKey, BrowserStorageService, AuthUtils
} from '@wlms-web/utils';
import { WarehouseLinesdetails } from '../../config/table-structure-warehouselines';
import { WarehouseLinesService } from '../../services/whl.service';
import { UiHelperService } from '@wlms-web/ui-kit';
import { ManageWarehouselineComponent } from './manage-warehouseline/manage-warehouseline.component';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import * as _ from 'lodash';
import { Router } from '@angular/router';
import { CopyWhlContractComponent } from './copy-whl-contract/copy-whl-contract.component';
import { ResetPreferenceComponent } from 'libs/ui-kit/src/lib/reset-preference/reset-preference.component';

@Component({
  selector: 'wlms-warehouselines',
  templateUrl: './warehouselines.component.html',
  styleUrls: ['./warehouselines.component.scss']
})
export class WarehouselinesComponent implements OnInit {
  rowData = [];
  columnDefs = [...WarehouseLinesdetails];
  paginationPageSize = 50;
  screenActionPrivileges = ScreenActionPrivileges;
  @Input() suppressActionPrivilege = false;
  @Input() suppressRightClickPrivilege = false;
  noRowsOverlayFlags = { ...NoRowsOverlayFlags };
  @ViewChild('warehouseGrid') warehouseGrid: any;
  @ViewChild('xlModal') xlModal: any;
  indexList = [];
  modalRef?: BsModalRef;
  userDetails: any;
  fundingCutOff = new Date();
  @Output() warehouseReloaded: EventEmitter<any> = new EventEmitter<any>();

  preferance: any[] = [];
  filterPreferance: any[] = [];
  filterModel: any;


  columnMaster = [];
  selectedTabPreference: any[] = [];

  constructor(private warehouselinesservice: WarehouseLinesService,
    public sharedService: SharedService,
    private authUtils: AuthUtils,
    public modalService: BsModalService,
    private browserStorageService: BrowserStorageService,
    private router: Router,
    private uiHelperService: UiHelperService) { }

  ngOnInit(): void {

    this.userDetails = this.authUtils.getLoggedInUserDetails();
    this.getColumnMaster();
    this.getWarehouselineData();
    if (this.authUtils.doesUserAllowedToPerform(this.screenActionPrivileges.EditWarehouseline)) {
      this.getIndex();
    }
    this.sharedService.disableRestButton = false;
    this.getUserPreference();
  }

  openWarehouseDetail(event) {
    if (event) {
      localStorage.setItem('currentWarehouse', JSON.stringify(event));
      this.router.navigate(['/warehouseline-details']);
    }
  }

  getWarehouselineData() {
    this.rowData = [];
    this.sharedService.loadGrid.next({ isLoad: true });
    return this.warehouselinesservice.getWarehouseLines()
      .subscribe(response => {
        this.rowData = [...response['details']['warehouseLineDetails']];
        this.sharedService.loadGrid.next({ isLoad: false });
        if (this.rowData.length > 0) {
          this.warehouseReloaded.next(this.rowData);
        }
      });
  }

  openEditWarehouseLine(event?) {
    const data = {
      isEdit: true,
      modalData: event.data,
      indexList: this.indexList
    };
    const title = 'Edit Warehouseline';
    this.uiHelperService.askConfirmation(ManageWarehouselineComponent, 'modal-xl', title, data, 'Cancel', 'Update',
      (data: any) => {
        this.saveWarehouse(data);
      });
  }

  openAddWarehouseLine() {
    const data = {
      isEdit: false,
      modalData: {},
      indexList: this.indexList
    };
    const title = 'Add Warehouseline';
    this.uiHelperService.askConfirmation(ManageWarehouselineComponent, 'modal-xl', title, data, 'Cancel', 'Save',
      (data: any) => {
        this.saveWarehouse(data);
      });
  }

  openWlCopyContact(e?) {
    this.sharedService.modalSubmitValid = true;
    const data = {
      isEdit: false,
      modalData: e.data,
      indexList: this.indexList
    };
    const title = 'Add Contract';
    this.uiHelperService.askConfirmation(CopyWhlContractComponent, 'modal-md', title, data, 'Cancel', 'Save',
      (data: any) => {
        const request = {
          "WarehouseLineContractDetailId": data.data.modalData.contractId,
          "WarehouseLineId": data.data.modalData.WarehouseLineId,
          "StartDate": data.data.modalData.StartDate,
          "ExpiryDate": data.data.modalData.ExpiryDate
        }
        this.saveContract(request);
      });
  }

  saveContract(data) {
    this.warehouselinesservice.copyContract(data).subscribe(response => {
      if (response && response['details'] && response['details'].Status == 1) {
        this.getWarehouselineData();
        this.uiHelperService.showSnackBar('Copied Contracts Successfully', SnackbarConfig.success);
      } else if (response && response['details'] && response['details'].Status == 2) {
        this.uiHelperService.showSnackBar(`Failed to save as the the same contract exists.`, SnackbarConfig.error);
      }
    })
  }

  saveWarehouse(data) {
    this.warehouselinesservice.addOrEditWarehouselineById(data.data.modalData).subscribe(response => {
      if (response && response['details'] && response['details'].Status == 1) {
        this.getWarehouseline();
        this.getWarehouselineData();
        this.uiHelperService.showSnackBar('Changes Saved Successfully.', SnackbarConfig.success);
      } else if (response && response['details'] && response['details'].Status == 2) {
        this.uiHelperService.showSnackBar(`Failed to save as the name of the warehouseline is duplicate.`, SnackbarConfig.error);
      }
    })
  }

  getWarehouseline() {
    this.warehouselinesservice.GetWarehouseLineDetailsForLoanQueue().subscribe(response => {
      this.sharedService.warehouseLines = [...[...response['details']].map(obj => ({
        warehouseLineId: obj.WarehouseLineId,
        name: obj.WarehouseLineName,
        icon: obj.icon,
        code: obj.shortCode,
        vendorId: obj.vendorId
      })),
      { name: 'Unassigned', icon: 'icons_slash.svg', code: null, vendorId: 0 }];
    });
  }

  getIndex() {
    this.warehouselinesservice.getIndex().subscribe((response) => {
      if (response) {
        this.indexList = response['details'];
      }
    })
  }

  noOverlay(event) {
    this.warehouseGrid.grid.api.setGridOption("noRowsOverlayComponentParams", {
      noRowsMessageFunc: function () {
        return {
          blankTemplateFlag: 'warehouses',
        };
      }
    });
  }

  saveUserPreferences() {
    this.reorderColumnMaster();
    this.collectUserPreferences();
    this.selectedTabPreference = [];
    this.selectedTabPreference.push({"tabid": GridMaster.Warehouseline});

    const request = {
      Tab: this.selectedTabPreference,
      Column: this.preferance
    };

    this.warehouselinesservice.saveUserPreferences(request).subscribe((response: any) => {
      if (response) {
        this.sharedService.userPreferenceChanged = false;
        this.uiHelperService.showSnackBar('User preferences saved successfully', SnackbarConfig.success);
        this.getUserPreference();
        this.sharedService.disableRestButton =  true;
      }
    }, (error) => {
      this.uiHelperService.showSnackBar('Failed to save user preference', SnackbarConfig.error);
    });
  }

  getColumnMaster() {
    this.warehouselinesservice.getColumnMaster(GridMaster.Warehouseline).subscribe((response: any) => {
      if (response) {
        this.columnMaster = response.details;
        this.reorderColumnMaster();
      }
    });
  }

  reorderColumnMaster() {
    _.forEach(this.columnDefs, (col: any, index: number) => {
      const masterIndex = _.findIndex(this.columnMaster, (master: any) => {
        return master.ColumnName == col.field;
      });
      if (masterIndex != -1) {
        this.columnDefs[index]['ColumnMasterId'] = this.columnMaster[masterIndex].ColumnMasterId;
      }
    });
  }

  collectUserPreferences() {
    this.preferance = [];
    const columnState = this.warehouseGrid.getColumnState();
    _.forEach(columnState, (state: any, index: number) => {
      let columnMasterId = null;
      const columnIndex = _.findIndex(this.columnDefs, (col) => {
        return col.field == state.colId;
      });
      if (columnIndex != -1) {
        const col = { ...this.columnDefs[columnIndex] };
        columnMasterId = col['ColumnMasterId'];
        if (columnMasterId > 0) {
          this.preferance.push({
            ColumnMasterId: columnMasterId,
            ColumnOrder: index,
            SortOrder: state.sort,
            GroupOrder: state.rowGroupIndex != null ? state.rowGroupIndex + 1 : state.rowGroupIndex,
            IsVisible: state.hide ? 0 : 1
          });
        }
      }
    });
  }

  getUserPreference() {
    if (this.authUtils.doesUserAllowedToPerform(this.screenActionPrivileges.GetWarehouseLineSavePreference)) {
      this.warehouselinesservice.getUserPreferences(this.userDetails?.email, 'WarehouseLine').subscribe(response => {
        this.persistFilterData();
        if (response) {
          this.preferance = response.details;
          this.sharedService.disableRestButton = this.preferance.map((pref) => pref.TabId).includes(GridMaster.Warehouseline);
          this.setPreference()

        }
      });
    }
  }

  setPreference() {
    this.reorderColumnMaster();
    const tabPreference = _.filter(this.preferance, (pref: any) => {
      return pref.TabId == GridMaster.Warehouseline;
    });
    if (tabPreference && tabPreference.length > 0) {
      _.forEach(this.columnDefs, (col: any, index: number) => {
        const prefColumn = _.filter(tabPreference, (pref: any) => {
          return pref.ColumnMasterId == col.ColumnMasterId;
        });
        const column = { ...this.columnDefs[index] };
        this.columnDefs[index] = this.updateColumnProps(prefColumn, column);
      });
      const sortedCols = _.sortBy(this.columnDefs, (col: any) => {
        return col['ColumnOrder'];
      });
      this.columnDefs = [...sortedCols];
    } else {
      this.columnDefs = [...WarehouseLinesdetails];
    }
  }

  updateColumnProps(prefColumn, column) {
    if (prefColumn && prefColumn.length > 0) {
      column['sort'] = prefColumn[0].SortOrder;
      column['ColumnOrder'] = prefColumn[0].ColumnOrder;
      column['hide'] = prefColumn[0].IsVisible == 1 ? false : true;
      if (prefColumn[0].GroupOrder > 0) {
        column['rowGroup'] = true;
        column['rowGroupIndex'] = prefColumn[0].GroupOrder - 1;
      } else {
        column['rowGroup'] = false;
      }
    }
    return column;
  }

  persistFilterData() {
    this.filterPreferance = [];
    this.filterModel = [];
    this.filterPreferance = this.browserStorageService.getLocalStorageValue(StorageKey.FILTERMODEL);
    const currentFilterData = this.filterPreferance ? this.filterPreferance.filter(x => x.tabId === GridMaster.Warehouseline)[0] : null;
    if (currentFilterData) {
      this.filterModel = currentFilterData.filterData;
      this.warehouseGrid.setFilterModel(this.filterModel);
    }
    else {
      this.warehouseGrid.setFilterModel(null);
    }
  }

  isUserPreferenceChanged() {
    return this.warehouseGrid && this.warehouseGrid.isUserPreferenceChanged();
  }

  showResetUserPreferenceModal() {

    const title = " Reset Columns to the default/userPreference View";

    const data = {
      "message": "You are about to reset  WarehouseLine Columns to the default/userPreference View.",
      enablePreference: this.sharedService.disableRestButton,
       action :  this.sharedService.disableRestButton ? 1: 0,
    };
    this.uiHelperService.askConfirmation(ResetPreferenceComponent, 'modal-lg', title, data, 'Cancel', 'Reset Preference', (data: any) => {
      this.resetUserPreference(data.data.action);
    });

  }

  resetUserPreference(action) {

    if(action ==0)
    {
      const request = {
        TabId: GridMaster.Warehouseline,

      };

      this.warehouselinesservice.ResetUserPreference(request).subscribe((response: any) => {
        let responseDetails = response.details;
        if (responseDetails.Response == "Success") {

          this.sharedService.disableRestButton =  false;
          this.uiHelperService.showSnackBar('Grid has been reset to default view successfully', SnackbarConfig.success);
          this.getUserPreference();
        }
      }, (error) => {
        this.uiHelperService.showSnackBar('Failed to reset user preference', SnackbarConfig.error);
      });
    }
    else{

      this.getUserPreference();

      this.uiHelperService.showSnackBar('Grid has been reset to saved user preference view successfully', SnackbarConfig.success);
    }

  }

}

import { CurrencyFormatter, ConditionalEditor, PercentageFormatter } from '@wlms-web/utils';

export const WarehouseLineswetOverridesdetails = [
    {
        "field": "wetOverrideId",
        "filter": "agNumberColumnFilter",
        hide: true,
        "suppressFiltersToolPanel": true,
        "suppressColumnsToolPanel": true
    },
    {
        "field": "warehouseLineName",
        "headerName": "Warehouse Line",
        "filter": "agTextColumnFilter",
        "width": 150,
    },    
    {
        "field": "lineLimit",
        "headerName": "Line Limit",
        "width": 200,
        "filter": "agNumberColumnFilter",
        valueFormatter: CurrencyFormatter.setCurrencyFormat,
        cellStyle: { 'text-align': 'right' },
    },
    {
        "field": "overrideTypeFirst",        
        "filter": "agNumberColumnFilter",
        hide: true,
        "suppressFiltersToolPanel": true,
        "suppressColumnsToolPanel": true
    },
    {
        "field": "overRideTypeNameFirst",
        "headerName": "Override Method (First 5 BD)",
        "filter": "agTextColumnFilter",
        "width": 200,
    },
    {
        "field": "overrideValueFirst",
        "filter": "agNumberColumnFilter",
        hide: true,
        "suppressFiltersToolPanel": true,
        "suppressColumnsToolPanel": true        
    },
    {
        "field": "overridePercentageFirst",
        "headerName": "Override % (First 5 BD)",
        "width": 200,
        "filter": "agNumberColumnFilter",        
        valueFormatter: PercentageFormatter.setPercentageFormat,
        cellStyle: { 'text-align': 'right' },
    },
    {
        "field": "overrideAmountFirst",
        "headerName": "Override $ (First 5 BD)",
        "width": 200,
        "filter": "agNumberColumnFilter",        
        valueFormatter: CurrencyFormatter.setCurrencyFormat,
        cellStyle: { 'text-align': 'right' },
    },
    {
        "field": "overrideTypeMid",
        "filter": "agNumberColumnFilter",
        hide: true,
        "suppressFiltersToolPanel": true,
        "suppressColumnsToolPanel": true
    },
    {
        "field": "overRideTypeNameMid",
        "headerName": "Override Method (Mid Month +/- 5 BD)",
        "filter": "agTextColumnFilter",
        "width": 150,
    },
    {
        "field": "overrideValueMid",
        "filter": "agNumberColumnFilter",
        hide: true,
        "suppressFiltersToolPanel": true,
        "suppressColumnsToolPanel": true        
    },
    {
        "field": "overridePercentageMid",
        "headerName": "Override % (Mid Month +/- 5 BD)",
        "width": 200,
        "filter": "agNumberColumnFilter",        
        valueFormatter: PercentageFormatter.setPercentageFormat,
        cellStyle: { 'text-align': 'right' },
    },
    {
        "field": "overrideAmountMid",
        "headerName": "Override $ (Mid Month +/- 5 BD)",
        "width": 200,
        "filter": "agNumberColumnFilter",        
        valueFormatter: CurrencyFormatter.setCurrencyFormat,
        cellStyle: { 'text-align': 'right' },
    },    
    {
        "field": "overrideTypeLast",
        "filter": "agNumberColumnFilter",
        hide: true,
        "suppressFiltersToolPanel": true,
        "suppressColumnsToolPanel": true
    },   
    {
        "field": "overRideTypeNameLast",
        "headerName": "Override Method (Last 5 BD)",
        "filter": "agTextColumnFilter",
        "width": 150,
    },
    {
        "field": "overrideValueLast",
        "filter": "agNumberColumnFilter",
        hide: true,
        "suppressFiltersToolPanel": true,
        "suppressColumnsToolPanel": true    
    }, 
    {
        "field": "overridePercentageLast",
        "headerName": "Override % (Last 5 BD)",
        "width": 200,
        "filter": "agNumberColumnFilter",        
        valueFormatter: PercentageFormatter.setPercentageFormat,
        cellStyle: { 'text-align': 'right' },
    },
    {
        "field": "overrideAmountLast",
        "headerName": "Override $ (Last 5 BD)",
        "width": 200,
        "filter": "agNumberColumnFilter",        
        valueFormatter: CurrencyFormatter.setCurrencyFormat,
        cellStyle: { 'text-align': 'right' },
    },    
    {
        "field": "comments",
        "headerName": "Comments",
        "filter": "agTextColumnFilter",
        "width": 350,
        tooltipField: 'Comments'
    },
    {
        "field": "action",
        "headerName": "",
        "width": 90,
        "active": true,
        "cellRenderer": "actionColumnRendererComponent",
        "resizable": false,
        "pinned": "right",
        "suppressMovable": true,
        "suppressSorting": true,
        "suppressRowClickSelection": true,
        "lockPinned": true,
        showRowGroup: true,
        "suppressColumnsToolPanel": true,
        "sortable": false,
        "suppressMenu": true
    }
]

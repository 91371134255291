import { UiHelperService } from './../../../services/ui-helper.service';
import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { SharedService, ScreenActionPrivileges, AuthUtils } from '@wlms-web/utils';

@Component({
  selector: 'wlms-action-column-renderer',
  templateUrl: './action-column-renderer.component.html',
  styleUrls: ['./action-column-renderer.component.scss']
})
export class ActionColumnRendererComponent implements ICellRendererAngularComp {
  rowData: any;
  params: any;
  buttonGroup: any = [];
  selectedAction: any;
  disableDropdown=false;
  containerClass = '.ag-body-viewport';
  defaultWarehouseList = [];
  basePath = '/assets/images/';
  excludedList = [];
  contentVisibility = {
    infoIcon: true,
    empowerIcon: true,
    dropdown: true,
    delete: false,
    edit: false,
    add: false,
    copyContract: true,
    copyTranche: true,
    viewFundingFile: true,
    viewReport: true,
  }

  constructor(private sharedService: SharedService,
    private uiHelperService: UiHelperService, private authUtils: AuthUtils) {
    this.defaultWarehouseList = [...this.sharedService.warehouseLines];
  }

  agInit(params): void {
    this.params = params;
    if (this.params.data && this.params.data.treasuryFunding && (this.params.data.treasuryFunding.eligibleWarehouseLines || this.params.data.treasuryFunding.warehouseLine)) {
      this.disableDropdown = this.params.data.IsLoanExported;
      let warehouses = [];
      if (this.params.data.treasuryFunding.eligibleWarehouseLines) {
        // find index from defaultWarehouseList using name
        this.params.data.treasuryFunding.eligibleWarehouseLines.forEach((element) => {
          const wl = this.defaultWarehouseList.find((y: any) => {
            return y.warehouseLineId ? y.name == element : -1;
          });
          warehouses.push(wl.warehouseLineId);
        });
      }
      else if (this.params.data.treasuryFunding.warehouseLine) {
        warehouses.push(this.params.data.treasuryFunding.warehouseLine);
      }

      this.setExcludedList(warehouses);
      const includedList = this.setIncludedList(warehouses);
      if (this.excludedList.length > 0) {
        this.buttonGroup = includedList.concat(this.excludedList);
      }
      this.selectedAction = this.defaultWarehouseList.filter((warehouse: any) => {
        return warehouse.warehouseLineId && this.params.data.treasuryFunding.warehouseLine ?
          warehouse.warehouseLineId == this.params.data.treasuryFunding.warehouseLine : ''
      })[0];
    }
    else if (this.params.data && this.params.data.treasuryFunding && !this.params.data.treasuryFunding.eligibleWarehouseLines) {
      this.setWarehouselistForUnassigned();
    }
    this.setContentVisiblity();
  }

  setContentVisiblity() {
    this.contentVisibility = {
      infoIcon: this.params.data && (
        (this.params.data.warehouseLineId && !this.params.data.hierarchy &&
          this.params.data.warehouseLineDailyCapId == undefined && this.params.data.wetOverrideId == undefined)
        || this.params.data.loanNumber) && !this.isItExportHistory(),
      empowerIcon: this.params.data && this.params.data.loanNumber && !this.isItExportHistory(),
      dropdown: this.params.data && this.params.data.loanNumber && !this.isItExportHistory(),

      delete: this.params.data && ((this.params.data.timeId && !this.params.data.isAdd) ||
        (this.params.data.hierarchy && this.params.data.isLoansAssigned != 1) ||
        (this.authUtils.doesUserAllowedToPerform(ScreenActionPrivileges.EditTrancheTab) && this.params.data.warehouseLineTrancheId)),
      edit: this.params.data &&
        ((this.params.data.warehouseLineDailyCapId && this.authUtils.doesUserAllowedToPerform(ScreenActionPrivileges.EditDailyCabTab)) ||
          (this.authUtils.doesUserAllowedToPerform(ScreenActionPrivileges.EditWarehouseline) &&
            ((this.params.data.warehouseLineId) ||
              (this.params.data.hierarchy || this.params.data.warehouseLineContractAmendmentId)) ||
            (this.authUtils.doesUserAllowedToPerform(ScreenActionPrivileges.EditTrancheTab) && this.params.data.warehouseLineTrancheId))),
      add: this.params.data &&
        ((this.params.data.hierarchy && this.params.data.hierarchy.length == 1) ||
          (this.authUtils.doesUserAllowedToPerform(ScreenActionPrivileges.EditTrancheTab) && this.params.data.warehouseLineTrancheId)),
      copyContract: this.params.data && ((
        (this.params.data.warehouseLineId && !this.params.data.hierarchy &&
          this.params.data.warehouseLineDailyCapId == undefined && this.params.data.wetOverrideId == undefined)
        || this.params.data.LoanNumber)
        && this.authUtils.doesUserAllowedToPerform(ScreenActionPrivileges.CopyContract)
        && this.params.data.contractCount > 0),
      copyTranche: this.params.data &&
        ((this.params.data.hierarchy && this.params.data.hierarchy.length == 1) ||
          (this.authUtils.doesUserAllowedToPerform(ScreenActionPrivileges.EditTrancheTab) && this.params.data.warehouseLineTrancheId)),
      viewFundingFile: this.isItExportHistory(),
      viewReport: this.isItReportsGrid(),
    }
  }

  isItExportHistory(){
    return this.params.grid == 'exportHistory';
  }

  isItReportsGrid(){
    return this.params.grid == 'reports';
  }

  setWarehouselistForUnassigned() {
    this.buttonGroup = [];
    this.defaultWarehouseList.forEach((element: any, index: any) => {
      element = {
        ...element,
        icon: this.basePath + this.defaultWarehouseList[index].icon,
        inRuleExcluded: true
      }
      this.buttonGroup.push(element);
    });
  }

  setExcludedList(warehouses) {
    const  warehouseLineList  = this.params.data.treasuryFunding.warehouseLine?
             this.defaultWarehouseList.filter(x=>x.warehouseLineId != this.params.data.treasuryFunding.warehouseLine)
             : this.defaultWarehouseList;

    warehouseLineList.forEach((x: any) => {
      if (!warehouses.some((y: any) => x.warehouseLineId && y ? x.warehouseLineId == y : x.warehouseLineId == y)) {
          this.excludedList.push({
            ...x,
            icon: this.basePath + x.icon,
            name: x.name,
            inRuleExcluded: true
          });
      }
    });
  }

  setIncludedList(warehouses) {
    const warehouseLines = [];
    // Added assigned warehouse to include list to show the white background start here //

    if (this.params.data.treasuryFunding.warehouseLine) {
      const index = this.defaultWarehouseList.findIndex((y: any) => {
        return y.warehouseLineId && this.params.data.treasuryFunding.warehouseLine ? y.warehouseLineId == this.params.data.treasuryFunding.warehouseLine : -1;
      });

      if (index != -1) {
        warehouseLines.push({
          ...this.defaultWarehouseList[index],
          icon: this.basePath + this.defaultWarehouseList[index].icon,
          name: this.defaultWarehouseList[index].name,
          inRuleExcluded: false
        });
      }
    }

    // Added assigned warehouse to include list to show the white background End here //
    warehouses.forEach((element: string, index: number) => {
      const warehouseIndex = this.defaultWarehouseList.findIndex((warehouse: any) => {
        return warehouse.warehouseLineId ? warehouse.warehouseLineId == element: -1;
      });
      if (warehouseIndex != -1) {
        // check if the warehouse already push the warehouse eligible list //
        if (!warehouseLines.find(x => x.code == this.defaultWarehouseList[warehouseIndex].code)) {
          warehouseLines.push({
            ...this.defaultWarehouseList[warehouseIndex],
            icon: this.basePath + this.defaultWarehouseList[warehouseIndex].icon,
            name: this.defaultWarehouseList[warehouseIndex].name,
            inRuleExcluded: false
          });
        }
      }
    });
    return warehouseLines;
  }

  refresh(): boolean {
    return true;
  }

  showInfoPanel() {
    if (this.params.data && this.params.data.loanNumber) {
      this.sharedService.slidePanelOpen.next(this.params.data);
    } else if (this.params.data && this.params.data.WarehouseLineName) {
      this.params.context ? this.params.context.thisComponent.openWarehouseDetail.emit(this.params.data) : '';
    }
  }

  reviewLoan() {
    this.params.context ? this.params.context.thisComponent.reviewLoan.emit(this.params) : '';
  }

  delete() {
    this.params.context ? this.params.context.thisComponent.delete.emit(this.params) : '';
  }

  edit() {
    this.params.context ? this.params.context.thisComponent.edit.emit(this.params) : '';
  }

  save() {
    this.params.context ? this.params.context.thisComponent.save.emit(this.params) : '';
  }

  add() {
    this.params.context ? this.params.context.thisComponent.add.emit(this.params) : '';
  }

  undo() {
    this.params.context ? this.params.context.thisComponent.undo.emit(this.params) : '';
  }

  viewFundingFile() {
    this.params.context ? this.params.context.thisComponent.viewFundingFile.emit(this.params) : '';
  }

  viewReport() {
    this.params.context ? this.params.context.thisComponent.viewReport.emit(this.params) : '';
  }

  copyContract() {
    this.params.context ? this.params.context.thisComponent.copyContract.emit(this.params) : '';
  }

  copyTranche() {
    this.params.context ? this.params.context.thisComponent.copyTranche.emit(this.params) : '';
  }

  onWarehouselineSelected(event) {
    if (this.params.context && this.params.data) {
      this.params.context.thisComponent.optionSelected.emit({ ...event, loanDetails: [this.params.data] });
    } else if (this.params.context && !this.params.data && this.params.node.allChildrenCount) {
      this.params.context.thisComponent.optionSelected.emit({ ...event, loanDetails: this.params.node.allLeafChildren });
    }
  }
}



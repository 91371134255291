import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigService, ApiConfig, GridMaster } from '@wlms-web/utils';
import { BaseApiService } from '@ldsm/utils';


@Injectable({
  providedIn: 'root'
})
export class WarehouseLinesService extends BaseApiService {

  constructor(protected app: ConfigService, protected http: HttpClient) {
    super(
      http,
      app.getAppConfiguration().warehouselineServiceUrl,
      '',
      ''
    );
  }

  GetWarehouseLineDetailsForLoanQueue() {
    return this.get('WarehouseLineDetail', ApiConfig.DISABLEGETTIMECACHE);
  }

  getWarehouseLines() {
    return this.get('WarehouseLineDetailsForGrid', ApiConfig.DISABLESEARCHLOANCACHE);
  }

  getIndex() {
    return this.get('WarehouseLineIndex', ApiConfig.DISABLESEARCHLOANCACHE);
  }

  getTimeAvailability() {
    return this.get('AllWarehouseLineTimeAvailability', ApiConfig.DISABLESEARCHLOANCACHE);
  }

  getDailyCapAvailability() {
    return this.get('WarehouseLineDailyCap', ApiConfig.DISABLESEARCHLOANCACHE);
  }

  SaveEditDailyCapAvailability(data) {
    return this.put('WarehouseLineDailyCaps/UpdateWarehouseLineDailyCap', data);
  }

  getWarehouseLineDetailsByWarehouseName(title: string) {
    return this.get('StaticFiles/Json/warehouselineData.json');
  }

  getWarehouseLinesCapacityDetails() {
    return this.get('StaticFiles/Json/WarehouselineCapacityDetails.json');
  }

  editWarehouseLine(data) {
    return this.put('WarehouseLine/UpdateWarehouseLineDetails', data);
  }

  editTimeAvailablity(data) {
    return this.put('WarehouseLineTimeAvailability/UpsertWarehouseLineTimeAvailability', data);
  }

  getWarehouselineById(id) {
    return this.get('WarehouseLine/GetWarehouseLineDetailsForEdit?warehouselineId=' + id, ApiConfig.DISABLESEARCHLOANCACHE);
  }

  addOrEditWarehouselineById(data) {
    return this.put('WarehouseLine/UpsertWarehouseLineDetails', data);
  }

  getTranches(id) {
    return this.get('WarehouseLineTranche/GetWarehouseLineTranchesByContract?warehouselineContractId=' + id,
      ApiConfig.DISABLESEARCHLOANCACHE);
  }

  getContractsPerWarehouse(id) {
    return this.get('WarehouseLine/GetWarehouseLineContracts?warehouselineId=' + id, ApiConfig.DISABLESEARCHLOANCACHE);
  }

  saveTranche(data) {
    return this.put('WarehouseLineTranche/UpsertWarehouseLineTranche', data);
  }

  getAllWetOverrideDetails() {
    return this.get('WarehouseLineWetOverride', ApiConfig.DISABLESEARCHLOANCACHE);
  }

  UpdateWhWetOverride(data) {
    return this.put('WarehouseLineWetOverrides/UpdateWarehouseLineWetOverrideList', data);
  }

  copyContract(data) {
    return this.post('WarehouseLine/CopyContract', data);
  }

  saveUserPreferences(request) {
    return this.post('@userServiceUrl/SaveUserPreference', request);
  }

  getUserPreferences(userName, gridName) {
    return this.get('@userServiceUrl/UserPreferencesForGrid?GridName=' + gridName + '&UserName=' + userName, ApiConfig.DISABLELOANCACHE);
  }

  getColumnMaster(id) {
    let gridName = 'WarehouseLine';

    switch (id) {
      case GridMaster.Warehouseline:
        gridName = 'WarehouseLine';
        break;
      case GridMaster.DailyCaps:
        gridName = 'DailyCaps';
        break;
      case GridMaster.WetOverride:
        gridName = 'WetOverrides';
        break;
      case GridMaster.Tranche:
        gridName = 'Tranches';
        break;
    }
    return this.get('@userServiceUrl/ColumnsForGridPreferences?GridName=' + gridName);
  }

  ResetUserPreference(request) {
    return this.post('@userServiceUrl/ResetUserPreference', request);
  }

  copyTanche(data) {
    return this.put('WarehouseLineTranche/CopyTranche', data);
  }
}

import { CurrencyFormatter, ConditionalEditor } from '@wlms-web/utils';

export const WarehouseLinesDailyCapdetails = [
  {
    "field": "warehouseLineName",
    "headerName": "Warehouse Line",
    "filter": "agTextColumnFilter",
    "width": 150,
  },
  {
    "field": "startDate",
    "headerName": "Start Date",
    "cellRenderer": "formatDateRenderer",
    "filter": "agDateColumnFilter",
    "width": 150,
  },
  {
    "field": "warehouseLineDailyCapId",
    "filter": "agDateColumnFilter",
    hide: true,
    "suppressFiltersToolPanel": true,
    "suppressColumnsToolPanel": true
  },
  {
    "field": "expiryDate",
    "headerName": "End Date",
    "cellRenderer": "formatDateRenderer",
    "filter": "agDateColumnFilter",
    "width": 150,
  },
  {
    "field": "value",
    "headerName": "Available Amount",
    "width": 200,
    "filter": "agNumberColumnFilter",
    valueFormatter: CurrencyFormatter.setCurrencyFormat,
    cellStyle: { 'text-align': 'right' },
  },
  {
    "field": "availableAmountCap",
    "headerName": "Available Amount Cap",
    "width": 200,
    "filter": "agNumberColumnFilter",
    valueFormatter: CurrencyFormatter.setCurrencyFormat,
    cellStyle: { 'text-align': 'right' },
  },
  {
    "field": "lineLimitAmountCap",
    "headerName": "Line Limit Cap",
    "width": 200,
    "filter": "agNumberColumnFilter",
    valueFormatter: CurrencyFormatter.setCurrencyFormat,
    cellStyle: { 'text-align': 'right' },
  },
  {
    "field": "comments",
    "headerName": "Comments",
    "filter": "agTextColumnFilter",
    "width": 350,
    tooltipField: 'Comments'
  },
  {
    "field": "action",
    "headerName": "",
    "width": 90,
    "active": true,
    "cellRenderer": "actionColumnRendererComponent",
    "resizable": false,
    "pinned": "right",
    "suppressMovable": true,
    "suppressSorting": true,
    "suppressRowClickSelection": true,
    "lockPinned": true,
    showRowGroup: true,
    "suppressColumnsToolPanel": true,
    "sortable": false,
    "suppressMenu": true
  }
]

import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { WarehouseLineswetOverridesdetails } from '../../config/table-structure-wet-overrides';
import {
  SharedService, NoRowsOverlayFlags, ScreenActionPrivileges, SnackbarConfig, OverrideType, GridMaster,
  StorageKey, BrowserStorageService, AuthUtils
} from '@wlms-web/utils';
import { WarehouseLinesService } from '../../services/whl.service';
import { UiHelperService } from '@wlms-web/ui-kit';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Router } from '@angular/router';
import { EditWetOverridesComponent } from './edit-wet-overrides/edit-wet-overrides.component';
import * as _ from 'lodash';
import { ResetPreferenceComponent } from 'libs/ui-kit/src/lib/reset-preference/reset-preference.component';

@Component({
  selector: 'wlms-wet-overrides',
  templateUrl: './wet-overrides.component.html',
  styleUrls: ['./wet-overrides.component.scss']
})
export class WetOverridesComponent implements OnInit {
  rowData = [];
  response = [];
  userDetails: any;
  columnDefs = [...WarehouseLineswetOverridesdetails];
  paginationPageSize = 50;
  screenActionPrivileges = ScreenActionPrivileges;
  @Input() suppressActionPrivilege = false;
  @Input() suppressRightClickPrivilege = false;
  noRowsOverlayFlags = { ...NoRowsOverlayFlags };
  @ViewChild('wetOverridesGrid') wetOverridesGrid: any;
  @Output() warehouseReloaded: EventEmitter<any> = new EventEmitter<any>();
  overrideType = [...OverrideType];

  preferance: any[] = [];
  filterPreferance: any[] = [];
  filterModel: any;
  
  columnMaster = [];
  selectedTabPreference: any[] = [];

  constructor(private warehouselinesservice: WarehouseLinesService,
    public sharedService: SharedService,
    private authUtils: AuthUtils,
    public modalService: BsModalService,
    private browserStorageService: BrowserStorageService,
    private router: Router,
    private uiHelperService: UiHelperService) { }

  ngOnInit(): void {
    this.sharedService.disableRestButton = false;
    this.userDetails = this.authUtils.getLoggedInUserDetails();
    this.getColumnMaster();
    this.getAllWetOverrideDetails();
    this.getUserPreference();
  }

  getAllWetOverrideDetails() {
    this.rowData = [];
    this.sharedService.loadGrid.next({ isLoad: true });
    return this.warehouselinesservice.getAllWetOverrideDetails()
      .subscribe(response => {
        this.response = [...response['details']['wetOverrides']];
        this.response.forEach(x => {
          if (x.overrideTypeFirst == 1) {
            x.overRideTypeNameFirst = this.overrideType.filter(o => o.id == x.overrideTypeFirst)[0].name;
            x.overridePercentageFirst = x.overrideValueFirst;
            x.overrideAmountFirst = (x.lineLimit * x.overrideValueFirst) / 100;
          }
          else if (x.overrideTypeFirst == 2) {
            x.overRideTypeNameFirst = this.overrideType.filter(o => o.id == x.overrideTypeFirst)[0].name;
            x.overrideAmountFirst = x.overrideValueFirst;
          }
          if (x.overrideTypeMid == 1) {
            x.overRideTypeNameMid = this.overrideType.filter(o => o.id == x.overrideTypeMid)[0].name;
            x.overridePercentageMid = x.overrideValueMid;
            x.overrideAmountMid = (x.lineLimit * x.overrideValueMid) / 100;
          }
          else if (x.overrideTypeMid == 2) {
            x.overRideTypeNameMid = this.overrideType.filter(o => o.id == x.overrideTypeMid)[0].name;
            x.overrideAmountMid = x.overrideValueMid;
          }
          if (x.overrideTypeLast == 1) {
            x.overRideTypeNameLast = this.overrideType.filter(o => o.id == x.overrideTypeLast)[0].name;
            x.overridePercentageLast = x.overrideValueLast;
            x.overrideAmountLast = (x.lineLimit * x.overrideValueLast) / 100;
          }
          else if (x.overrideTypeLast == 2) {
            x.overRideTypeNameLast = this.overrideType.filter(o => o.id == x.overrideTypeLast)[0].name;
            x.overrideAmountLast = x.overrideValueLast;
          }
        })
        this.rowData = [...this.response];
        this.sharedService.loadGrid.next({ isLoad: false });
      });
  }

  openEditWetOverrides(event) {
    const data = {
      isEdit: true,
      wetOverridesData: { ...event.data }
    };
    const title = 'Edit Wet Overrides';
    this.uiHelperService.askConfirmation(EditWetOverridesComponent, 'modal-lg', title, data, 'Cancel', 'Update',
      (data: any) => {
        this.updateWetOverrides(data);
      });
  }

  updateWetOverrides(data) {
    this.warehouselinesservice.UpdateWhWetOverride(data.data.wetOverridesData).subscribe(response => {
      if (response && response['details'] && response['details'].Status == 1) {
        this.getAllWetOverrideDetails();
        this.uiHelperService.showSnackBar('Updated Wet Overrides Successfully', SnackbarConfig.success);
      }
    })
  }

  saveUserPreferences() {
    this.reorderColumnMaster();
    this.collectUserPreferences();
    this.selectedTabPreference = [];
    this.selectedTabPreference.push({"tabid": GridMaster.WetOverride});
    const request = {
      Tab: this.selectedTabPreference,
      Column: this.preferance
    };
   
    this.warehouselinesservice.saveUserPreferences(request).subscribe((response: any) => {
      if (response) {
        this.sharedService.userPreferenceChanged = false;
        this.uiHelperService.showSnackBar('User preferences saved successfully', SnackbarConfig.success);
        this.getUserPreference();
        this.sharedService.disableRestButton = true;
      }
    }, (error) => {
      this.uiHelperService.showSnackBar('Failed to save user preference', SnackbarConfig.error);
    });
  }

  getColumnMaster() {
    this.warehouselinesservice.getColumnMaster(GridMaster.WetOverride).subscribe((response: any) => {
      if (response) {
        this.columnMaster = response.details;
        this.reorderColumnMaster();
      }
    });
  }

  reorderColumnMaster() {
    _.forEach(this.columnDefs, (col: any, index: number) => {
      const masterIndex = _.findIndex(this.columnMaster, (master: any) => {
        return master.ColumnName == col.field;
      });
      if (masterIndex != -1) {
        this.columnDefs[index]['ColumnMasterId'] = this.columnMaster[masterIndex].ColumnMasterId;
      }
    });
  }

  collectUserPreferences() {
    this.preferance = [];
    const columnState = this.wetOverridesGrid.getColumnState();
    _.forEach(columnState, (state: any, index: number) => {
      let columnMasterId = null;
      const columnIndex = _.findIndex(this.columnDefs, (col) => {
        return col.field == state.colId;
      });
      if (columnIndex != -1) {
        const col = { ...this.columnDefs[columnIndex] };
        columnMasterId = col['ColumnMasterId'];
        if (columnMasterId > 0) {
          this.preferance.push({
            ColumnMasterId: columnMasterId,
            ColumnOrder: index,
            SortOrder: state.sort,
            GroupOrder: state.rowGroupIndex != null ? state.rowGroupIndex + 1 : state.rowGroupIndex,
            IsVisible: state.hide ? 0 : 1
          });
        }
      }
    });
  }

  getUserPreference() {
    if (this.authUtils.doesUserAllowedToPerform(this.screenActionPrivileges.GetWetOverRidesTabSavePreference)) {
      this.warehouselinesservice.getUserPreferences(this.userDetails?.email, 'WetOverrides').subscribe(response => {
        this.persistFilterData();
        if (response) {
          this.preferance = response.details;
          this.sharedService.disableRestButton = this.preferance.map((pref) => pref.TabId).includes(GridMaster.WetOverride);
          this.setPreference()
        }
      });
    }
  }

  setPreference() {
    this.reorderColumnMaster();
    const tabPreference = _.filter(this.preferance, (pref: any) => {
      return pref.TabId == GridMaster.WetOverride;
    });
    if (tabPreference && tabPreference.length > 0) {
      _.forEach(this.columnDefs, (col: any, index: number) => {
        const prefColumn = _.filter(tabPreference, (pref: any) => {
          return pref.ColumnMasterId == col.ColumnMasterId;
        });
        const column = { ...this.columnDefs[index] };
        this.columnDefs[index] = this.updateColumnProps(prefColumn, column);
      });
      const sortedCols = _.sortBy(this.columnDefs, (col: any) => {
        return col['ColumnOrder'];
      });
      this.columnDefs = [...sortedCols];
    } else {
      this.columnDefs = [...WarehouseLineswetOverridesdetails];
    }
  }

  updateColumnProps(prefColumn, column) {
    if (prefColumn && prefColumn.length > 0) {
      column['sort'] = prefColumn[0].SortOrder;
      column['ColumnOrder'] = prefColumn[0].ColumnOrder;
      column['hide'] = prefColumn[0].IsVisible == 1 ? false : true;
      if (prefColumn[0].GroupOrder > 0) {
        column['rowGroup'] = true;
        column['rowGroupIndex'] = prefColumn[0].GroupOrder - 1;
      } else {
        column['rowGroup'] = false;
      }
    }
    return column;
  }

  persistFilterData() {
    this.filterPreferance = [];
    this.filterModel = [];
    this.filterPreferance = this.browserStorageService.getLocalStorageValue(StorageKey.FILTERMODEL);
    const currentFilterData = this.filterPreferance ? this.filterPreferance.filter(x => x.tabId === GridMaster.WetOverride)[0] : null;
    if (currentFilterData) {
      this.filterModel = currentFilterData.filterData;
      this.wetOverridesGrid.setFilterModel(this.filterModel);
    }
    else {
      this.wetOverridesGrid.setFilterModel(null);
    }
  }

  isUserPreferenceChanged() {
    return this.wetOverridesGrid && this.wetOverridesGrid.isUserPreferenceChanged();
  }
  showResetUserPreferenceModal() {
    
    const title = " Reset Columns to the default/userPreference View";

    const data = {
      "message": "You are about to reset  WetOverRides Columns to the default/userPreference View.",
      enablePreference: this.sharedService.disableRestButton ,
       action :  this.sharedService.disableRestButton ? 1: 0,
    };
    this.uiHelperService.askConfirmation(ResetPreferenceComponent, 'modal-lg', title, data, 'Cancel', 'Reset Preference', (data: any) => {
      this.resetUserPreference(data.data.action);
    });
   
  }
  
  resetUserPreference(action) {
   
    if(action ==0)
    {
      const request = {
        TabId: GridMaster.WetOverride,
      
      };
     
      this.warehouselinesservice.ResetUserPreference(request).subscribe((response: any) => {
        let responseDetails = response.details;
        if (responseDetails.Response == "Success") {
          this.sharedService.disableRestButton  = false;
          this.uiHelperService.showSnackBar('Grid has been reset to default view successfully', SnackbarConfig.success);
          this.getUserPreference();
        }
      }, (error) => {
        this.uiHelperService.showSnackBar('Failed to reset user preference', SnackbarConfig.error);
      });
    }
    else{
     
      this.getUserPreference();

      this.uiHelperService.showSnackBar('Grid has been reset to saved user preference view successfully', SnackbarConfig.success);
    }
   
  }
}
